export const Tag = ({ title, isIcon = false, icon }) => {
  return (
    <div className="tag">
      {isIcon && (
        <>
          {icon ? (
            <img src={icon} alt="tag-icon" />
          ) : (
            <div className="tag__icon-wrapper"></div>
          )}
        </>
      )}
      {title && <span className="tag__text">{title}</span>}
    </div>
  );
};
