import { useEffect, useRef, useState } from "react";
import "./App.css";
import { Column, Dropdown } from "./components";
import { ApiEndPoints, icons, priorityMap } from "./constants";

function App() {
  const [isDisplayOptionsOpen, setIsDisplayOptionsOpen] = useState(false);
  const displayOptionsRef = useRef(null);

  const handleToggleDisplayOptions = () => {
    setIsDisplayOptionsOpen((prev) => !prev);
  };

  const handleClickOutside = (event) => {
    if (
      displayOptionsRef.current &&
      !displayOptionsRef.current.contains(event.target)
    ) {
      setIsDisplayOptionsOpen(false);
    }
  };

  const [grouping, setGrouping] = useState(
    localStorage.getItem("grouping") ?? "status"
  );
  const groupingOptions = ["status", "users", "priority"];

  const [ordering, setOrdering] = useState(
    localStorage.getItem("ordering") ?? "priority"
  );
  const orderingOptions = ["priority", "title"];

  const setGroupingLocalStorage = (g) => {
    setGrouping(g);
    localStorage.setItem("grouping", g);
  };

  const setOrderingLocalStorage = (o) => {
    setOrdering(o);
    localStorage.setItem("ordering", o);
  };

  const [groupingsData, setGroupingsData] = useState({
    status: ["backlog", "todo", "in progress", "done", "cancelled"],
    users: [],
    priority: [
      "no priority",
      "urgent priority",
      "high priority",
      "medium priority",
      "low priority",
    ],
  });

  const [data, setData] = useState({
    tickets: [],
    users: [],
  });

  const getUserNameById = (userId) => {
    const user = data.users.find((user) => user.id === userId);
    return user ? user.name.toLowerCase() : "";
  };

  const getFilteredTickets = (group, index) => {
    return data.tickets.filter((ticket) => {
      if (grouping === "users") {
        return (
          getUserNameById(ticket.userId).toLowerCase() === group.toLowerCase()
        );
      }
      if (grouping === "priority") {
        return ticket.priority === priorityMap[group.toLowerCase()];
      }
      return ticket.status.toString().toLowerCase() === group;
    });
  };

  const getOrderedTickets = (tickets) => {
    if (ordering === "priority") {
      return tickets.sort((a, b) => b.priority - a.priority);
    } else if (ordering === "title") {
      return tickets.sort((a, b) => a.title.localeCompare(b.title));
    }
    return tickets;
  };

  // for click away functionality on the display options menu
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const [isLoading, setIsLoading] = useState(true);

  // fetch data from api
  useEffect(() => {
    fetch(ApiEndPoints.getData)
      .then((res) => res.json())
      .then((data) => {
        setData(data);
        setGroupingsData((prev) => ({
          ...prev,
          users: data.users.map((user) => user.name),
        }));
        setIsLoading(false);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  return (
    <div className="App">
      <nav className="navbar">
        <button
          className="navbar__menu-button"
          onClick={handleToggleDisplayOptions}
        >
          <img src={icons.display} alt="display options" />
          Display
          <img src={icons.downArrow} alt="down arrow" />
        </button>
        {isDisplayOptionsOpen && (
          <div className="display-options" ref={displayOptionsRef}>
            <div className="display-option">
              <p className="display-option__title">Grouping</p>
              <Dropdown
                options={groupingOptions}
                selected={grouping}
                setSelected={setGroupingLocalStorage}
              />
            </div>
            <div className="display-option">
              <p className="display-option__title">Ordering</p>
              <Dropdown
                options={orderingOptions}
                selected={ordering}
                setSelected={setOrderingLocalStorage}
              />
            </div>
          </div>
        )}
      </nav>

      <div className="col-wrapper">
        {isLoading ? (
          <div className="loader">
            {/* <img src="./assets/loader.gif" alt="loader" /> */}
            <img src="https://www.w3schools.com/html/programming.gif" alt="" />
            Loading...
          </div>
        ) : (
          <>
            {groupingsData[grouping].map((group, index) => (
              <Column
                key={group}
                title={group}
                grouping={grouping}
                tickets={getOrderedTickets(getFilteredTickets(group, index))}
                data={data}
              />
            ))}
          </>
        )}
      </div>
    </div>
  );
}

export default App;
