import { useState } from "react";

export const ProfileChip = ({
  name = "Anoop Sharma",
  imageSource,
  isAvailable = false,
}) => {
  const colors = ["orange", "olive", "blue"];
  const [randomColor] = useState(
    colors[Math.floor(Math.random() * colors.length)]
  );
  const initials = name
    .split(" ")
    .map((n) => n[0])
    .join("")
    .toUpperCase();

  return (
    <div
      className={`profile-chip__image-wrapper profile-chip__image-wrapper--${randomColor}`}
    >
      {imageSource ? (
        <img src={imageSource} alt="" className="card__profile-image" />
      ) : (
        <>{initials}</>
      )}
      <div
        className={`profile-chip__status profile-chip__status--${
          isAvailable ? "available" : "unavailable"
        }`}
      ></div>
    </div>
  );
};
