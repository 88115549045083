import { Tag, ProfileChip } from "../../components";
import { icons } from "../../constants";

export const Card = ({
  id,
  title,
  tag,
  userId,
  status,
  priority,
  grouping,
  data,
}) => {
  const user = data.users.find((u) => u.id === userId);

  return (
    <div className="card">
      <div className="card__header">
        <span className="card__id">{id}</span>
        {grouping !== "users" && (
          <ProfileChip name={user.name} isAvailable={user.available} />
        )}
      </div>

      <div className="card__main">
        {grouping !== "status" && (
          <img
            src={icons[status.toLowerCase()]}
            alt={status}
            className="card__icon"
          />
        )}
        <h4 className="card__title">{title}</h4>
      </div>

      <div className="card__footer">
        {/* for priority tag */}
        {grouping !== "priority" && (
          <Tag isIcon={true} icon={icons.priorityOrdered[priority]} />
        )}

        {/* for tag array from api */}
        {tag.map((t) => (
          <Tag key={t} isIcon={true} title={t} />
        ))}
      </div>
    </div>
  );
};
