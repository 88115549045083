import { useState } from "react";
import { icons } from "../../constants";

export const Dropdown = ({ options, selected, setSelected }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleSelectOption = (option) => {
    setSelected(option);
    setIsOpen(false);
  };

  return (
    <div className="dropdown">
      <button
        className="dropdown__button"
        onClick={() => setIsOpen((prev) => !prev)}
      >
        {selected}
        <img
          src={icons.downArrow}
          alt="down arrow"
          className={`dropdown__icon ${isOpen ? "dropdown__icon--open" : ""}`}
        />
      </button>
      {isOpen && (
        <div className="dropdown__options">
          {options.map((option) => (
            <button
              key={option}
              className="dropdown__option"
              onClick={() => handleSelectOption(option)}
            >
              {option}
            </button>
          ))}
        </div>
      )}
    </div>
  );
};
